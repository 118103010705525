.App {
  text-align: center;
  font-family:'Courier New', Courier, monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #1a273f; */
  /* background-color: #808080; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  /* color: white; */
  /* background-image: url('./resources/gifs/web-bg-optimized.gif'); */
  background-image: url('./resources/images/web-bg-static-orange.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-size: 6rem;
}

@media (max-width: 1400px) {
  .App-header {
    background-position: center center;
  }
}

/* @media only screen and (max-width: 600px) {
  
} */