ul.w3-animate-opacity, aside.w3-animate-opacity {
  position: absolute;
  padding: 0.5rem 3.5rem;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.9);
  font-size: x-large;
  border-radius: 10px;
}

.service-button {
  /* background-color: #ff9955; */
  background: none;
  color: white;
  min-width: 22rem;
  margin: 0.5rem 0;
  border-radius: 10px;
  font-size: xx-large;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-button:hover {
  color: #429ef3;
  /* border-left: 1px solid white;
  border-right: 1px solid white; */
}

.activated {
  background-color: #429ef3;
  /* border-bottom: 1px solid white; */
  /* margin: 0.3rem; */
  border-radius: 10px;
}

.activated:hover {
  color: white;
}

.button-like-anchor {
  background: none;
  color: #429ef3;
  border: none;
  border-bottom: 1px solid #429ef3;
  text-decoration: none;
  cursor: pointer;
  font-size: large;
}

.center-ul {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(50%, -50%);
}

@media only screen and (max-width: 600px) {
  .center-ul {
    position: relative;
    transform: translate(0, -50%);
    width: 100%;
    overflow-y: scroll;
  }

  .service-button {
    min-width: 12rem;
    font-size: x-large;
  }

  aside.w3-animate-opacity {
    font-size: medium;
    padding: 0.5rem 1.5rem;
  }

  ul.w3-animate-opacity {
    font-size: medium;
    padding: 0.5rem 2rem;
  }
}