table {
  width: 80%;
  table-layout: fixed;
}

td {
  /* border: 1px solid #ddd; */
  padding: 5px;
  text-align: center;
}

.home-description {
  color: white;
  background-color: rgba(252, 130, 7, 0.65);
  padding: 1.5rem;
  border-radius: 10px;
  min-height: 28rem;
}

.home-hook {
  background-color: #1a273f;
  color: white;
  border-radius: 10px;
  padding: 0.5rem;
  font-size: large;
}

.home-button-container {
  margin-left: 2rem;
  /* margin-top: 1.5rem; */
  text-align: center;
  /* border: 2px solid #ff9955; */
  padding: 0.6rem;
  border-radius: 10px;
  background-color: #80808055;
}

.home-button {
  /* background-color: #ff9955; */
  background: none;
  color: white;
  padding: 0 0.5rem;
  margin: auto;
  border-radius: 10px;
  font-size: large;
  border: none; /* Removes the default button border */
  cursor: pointer; /* Changes the cursor when you hover over the button */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.home-button:hover, .home-img-button:hover {
  background-color: #ff7733; /* Darker shade when you hover over the button */
}

.home-images-container {
  width: 50%;
}

.home-logo-top {
  width: 12rem;
  top: 0; 
  left: 0; 
  position: absolute;
  margin: 1.5rem;
}

.home-main-img-below {
  position: relative;
  border-radius: 300px;
  width: 28rem;
  overflow: hidden;
}

ul {
  font-size: medium;
  text-align: start;
}

@keyframes inhale {
  0% {
    filter: brightness(1);
    transform: scale(1);
  }
  50% {
    filter: brightness(1.5);
    transform: scale(1.1);
  }
  100% {
    filter: brightness(1);
    transform: scale(1);
  }
}

.kofi-btn {
  border: 0px;
  height: 38px;
  margin-left: 0.6rem;
}

/* tr:nth-child(even) {
  background-color: #f2f2f2;
} */

@media only screen and (min-width: 601px) {
  .home-main-img-animate {
    animation: inhale 2s ease;
  }
}

@media only screen and (max-width: 767px) {
  .kofi-btn {
    max-height: 1.5rem;
  }
  .kofi-btn::before {
    content: "Support me \00a0";
  }
}

@media only screen and (max-width: 600px) {

  .home-logo-top {
    width: 6rem;
  }

  .home-description {
    font-size: larger;
    padding: 1rem;
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.85);
  }

  .home-button-container {
    width: 75vw;
    margin-left: 0.8rem;
    margin-top: 0.5rem;
    background-color: #80808090;
    z-index: 100;
  }

  .home-main-img-below {
    width: 6rem;
    top: 0;
    right: 0;
    position: absolute;
    padding: 0.8rem;
  }

  .small-screen-button-container {
    display: block;
    text-align: end;
  }

}