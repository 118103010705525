.slide-in {
  /* transform: translateX(0);
  transition: transform 0.3s ease-out; */
  animation-name: slide-in-animation;
  animation-duration: 1s;
}

.slide-out {
  /* transform: translateX(-200%);
  transition: transform 0.3s ease-out; */
  display: none;
  animation-name: slide-out-animation;
  animation-duration: 1s;
}

@keyframes slide-in-animation {
  from { 
    transform: translateX(-200%);
    /* display: inline-block; */
  }
  to { transform: translateX(0); }
}

@keyframes slide-out-animation {
  from { 
    transform: translateX(0);
  }
  to { 
    transform: translateX(-200%);
    display: none;
  }
}